<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import appConfig from '@/app.config';
import { campaignMethods, checkPermission, masterMethods } from '@/state/helpers';
import Multiselect from 'vue-multiselect';
import Editor from '@tinymce/tinymce-vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Swal from 'sweetalert2';
import Footer from '@/components/Footer/main.vue';
import { showMessage } from '@/utils/messages';

/**
 * Advanced-form component
 */
export default {
    page: {
        title: '当選配信文言テンプレート作成',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Editor,
        Multiselect,
        Layout,
        PageHeader,
        Footer
    },
    data() {
        return {
            obj: null,
            checkSecond: false,
            title: '当選配信文言テンプレート作成',
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '当選配信文言テンプレート作成',
                    active: true
                }
            ],
            shortCode: [
                '《キャンペーン名》',
                '《キャンペーン開始》',
                '《キャンペーン事務局TEL》',
                '《キャンペーン事務局Mailアドレス》',
                '《事務局開始》',
                '《事務局終了》',
                '《年末年始休み》'
            ],
            isLoading: false,
            template: {},
            templateDetail: {},
            listDataMasterTemplate: []
        };
    },
    watch: {},
    mounted() {
        this.getListMasterTemplate();
        setTimeout(() => {
            const editorIframe = document.querySelector('.tox-edit-area iframe');
            if (editorIframe) {
                editorIframe.contentDocument.querySelector('p').style.margin = '0';
            }
        });
    },
    methods: {
        ...masterMethods,
        ...campaignMethods,
        getListMasterTemplate() {
            this.templateList().then((data) => {
                if (data.code == 200) {
                    this.listDataMasterTemplate = data.data;
                }
            });
        },
        copy(data) {
            this.obj.insertContent(data);
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        getDraf() {
            if (this.template && !this.template.id) {
                this.resetTemplate();
            } else {
                this.templateGetDraf(this.template).then((data) => {
                    if (data.code == 200) {
                        if (data.data && data.data.template_id) {
                            this.templateDetail = data.data;
                            this.templateDetail.id = this.templateDetail.template_id;
                        } else {
                            this.getDetail(false);
                        }
                    }
                });
            }
        },
        getDetail(check) {
            this.templateGetDetail(this.template).then((data) => {
                if (data.code == 200) {
                    this.templateDetail = data.data;
                    if (check) {
                        showMessage(data.code, this.$bvToast, '前の状態に戻りました。');
                    }
                }
            });
        },
        removeDraft() {
            this.removeDraf(this.templateDetail).then((data) => {
                console.log(data);
            });
        },
        rollback() {
            this.getDetail(true);
        },
        deleteTemplate() {
            Swal.fire({
                title: '削除してもよろしいですか。',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5664d2',
                cancelButtonColor: '#b5b8bf',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.templateDelete(this.templateDetail).then((data) => {
                        if (data.code == 200) {
                            showMessage(data.code, this.$bvToast, '削除されました');
                            this.resetTemplate();
                            this.getListMasterTemplate();
                        } else {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                    });
                }
            });
        },
        resetTemplate() {
            this.template = {
                id: '',
                name: ''
            };
            this.templateDetail = {};
            this.obj.setContent('');
        },
        save(type) {
            if (type == 1) {
                this.isLoading = true;
                this.saveDraf(this.templateDetail).then((data) => {
                    if (data.code == 200) {
                        showMessage(data.code, this.$bvToast, '上書きとして保存されています。');
                    }
                    this.isLoading = false;
                });
            }
            if (type == 2) {
                this.isLoading = true;
                this.templateUpdate(this.templateDetail).then((data) => {
                    if (data.code == 200) {
                        this.removeDraft();
                        showMessage(data.code, this.$bvToast, '保存されています。');
                        this.getListMasterTemplate();
                        this.resetTemplate();
                    }
                    this.isLoading = false;
                });
            }
            if (type == 3) {
                if (!this.templateDetail.name) {
                    this.checkSecond = true;
                    return;
                }
                this.isLoading = true;
                this.templateCreate(this.templateDetail).then((data) => {
                    if (data.code == 200) {
                        showMessage(data.code, this.$bvToast, '別名で保存されています。');
                        this.$bvModal.hide('modal-othername');
                        this.getListMasterTemplate();
                        this.resetTemplate();
                    } else {
                        showMessage(data.code, this.$bvToast, this.$t('template.' + data.message));
                    }
                    this.isLoading = false;
                });
            }
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div v-if="!isLoading" class="row form__body--card form__common--center">
            <div class="col-lg-12">
                <div class="card form--sroll1 mb-0">
                    <div class="card-body">
                        <div>
                            <div class="form-group row">
                                <label class="col-sm-3">テンプレート選択</label>
                                <div class="col-sm-8">
                                    <div class="d-flex align-items-center">
                                        <div style="position: relative; width: 100%">
                                            <div>
                                                <multiselect
                                                    :showPointer="false"
                                                    :showNoResults="false"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    placeholder=""
                                                    selectLabel=""
                                                    :showNoOptions="false"
                                                    track-by="id"
                                                    label="name"
                                                    v-model="template"
                                                    :options="listDataMasterTemplate"
                                                    @close="getDraf()"
                                                ></multiselect>
                                                <i
                                                    v-if="template.id"
                                                    @click="
                                                        $event.stopPropagation();
                                                        resetTemplate();
                                                    "
                                                    style="cursor: pointer; position: absolute; right: 15px; top: 11px; background: #fff; z-index: 9"
                                                    class="fa fa-window-close"
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-3">内容</label>
                                <div class="col-sm-8">
                                    <div class="row">
                                        <div class="col-sm-9">
                                            <Editor
                                                ref="editor"
                                                id="editor2"
                                                SetContent="SetContent"
                                                model-events="change keydown blur focus paste"
                                                v-model="templateDetail.content"
                                                api-key="kzelqkvma9nowaevz1qbr6y90io13sa2l5izqkin42od20k5"
                                                :init="{
                                                    content_style: 'p { margin: 0 }',
                                                    height: 600,
                                                    menubar: true,
                                                    plugins: [
                                                        'hr advlist autolink lists link image charmap',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'print preview anchor insertdatetime media template',
                                                        'paste code help wordcount table code lists imagetools '
                                                    ],
                                                    toolbar:
                                                        'myCustomToolbarButton hr undo redo | formatselect | bold italic | \
                                          alignleft aligncenter alignright | \
                                          link image media | \
                                          bullist numlist outdent indent | help code\
                                          table tableinsertdialog tablecellprops tableprops | fullscreen ',
                                                    table_toolbar:
                                                        'tableprops tabledelete tablemergecells tablesplitcells | tableinsertrowbefore tableinsertrowafter tablerowprops tabledeleterow | tableinsertcolbefore tableinsertcolafter tablecellprops tabledeletecol',

                                                    setup: function (editor) {
                                                        obj = editor;
                                                    }
                                                }"
                                            />
                                        </div>
                                        <div class="col-sm-3 pr-0">
                                            <ul style="list-style: none; padding-left: 0">
                                                <li
                                                    @click="copy(item)"
                                                    style="
                                                        border: 1px solid #ddd;
                                                        display: inline-block;
                                                        margin-right: 8px;
                                                        margin-bottom: 8px;
                                                        cursor: pointer;
                                                    "
                                                    v-for="item in shortCode"
                                                    :key="item"
                                                >
                                                    {{ item }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <button type="button" v-if="template.id" @click="save(1)" class="btn btn-success ml-3">上書き保存</button>
                        <button type="button" v-if="template.id" @click="save(2)" class="btn btn-primary ml-3">保存</button>
                        <button type="button" @click="$bvModal.show('modal-othername')" class="btn btn-info ml-3">別名保存</button>

                        <button type="button" v-if="template.id" class="btn btn-light ml-3" @click="rollback()">前状態に戻す</button>

                        <button type="button" v-if="template.id" class="btn btn-danger ml-3" @click="deleteTemplate()">削除</button>
                    </div>
                </div>
            </Footer>

            <b-modal no-close-on-backdrop id="modal-othername" title="条件設定" title-class="font-18" scrollable hide-footer>
                <form class="needs-validation" autocomplete="off">
                    <div class="form-group row">
                        <label class="col-sm-3">別名入力</label>
                        <div class="col-sm-9 pl-0">
                            <div style="position: relative">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="templateDetail.name"
                                    :class="{
                                        'is-invalid': checkSecond && !templateDetail.name
                                    }"
                                />
                                <span v-if="checkSecond && !templateDetail.name" class="error">エンドクライアントを選択してください。</span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="d-flex justify-content-center">
                            <div class="text-center flex-fill">
                                <button type="button" class="btn btn-light mr-3" @click="$bvModal.hide('modal-othername')">キャンセル</button>
                                <button type="button" @click="save(3)" class="btn btn-primary">保存</button>
                            </div>
                        </div>
                    </div>
                </form>
            </b-modal>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>

<style lang="scss">
.mce-content-body p {
    margin: 0 !important;
}
</style>
